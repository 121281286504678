// Dashboard.tsx
import React from "react";
import Container from "../../compnents/Container";

const Home: React.FC = () => {
	return (
		<div>
			<Container>
				<h1>Hello</h1>
			</Container>
		</div>
	);
};

export default Home;
