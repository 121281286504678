// Dashboard.tsx
import React from "react";
import Container from "../../compnents/Container";
import {NavLink} from "react-router-dom";
import NavItem from "../../compnents/navbar/NavItem";

const Admin: React.FC = () => {
	return (
		<div className="">

		</div>
	);
};

export default Admin;
