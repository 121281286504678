import { useState } from "react";
import { motion } from "framer-motion";
import beastieBooking from "../../assets/img/logos/booking.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import NavItem from "./NavItem";

function RegularMenu() {
	const [activeItem, setActiveItem] = useState<string | null>(null);

	const handleItemClick = (item: string) => {
		console.log("Clicked item:", item);
		setActiveItem((prev) => (prev === item ? null : item));
	};

	const submenuVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: { opacity: 1, y: 0 },
	};

	return (
		<div className="relative flex justify-between w-[90%] mx-auto my-4">
			<div className="p-3 flex items-center justify-between w-full">
				<div className="w-[15%] p-3">
					<img src={beastieBooking} alt="Beastie Burgers" />
				</div>
				<div className="flex gap-5">
					<NavItem name="Floor Plan" onClick={() => handleItemClick("Floor Plan")} />
					<NavItem name="Bookings" onClick={() => handleItemClick("Bookings")} />
				</div>
				<div className="flex justify-center items-center">
					<div className="bg-torch-red-500 flex text-white rounded-full shadow px-10 p-4 gap-7 justify-evenly items-center">
						<div className="cursor-pointer">
							<FontAwesomeIcon icon={faEnvelope} size="xl" />
						</div>
						<div className="cursor-pointer">
							<FontAwesomeIcon icon={faBell} size="xl" />
						</div>
						<div className="cursor-pointer">
							<FontAwesomeIcon icon={faUser} size="xl" />
						</div>
					</div>
				</div>
			</div>

			{activeItem && (
				<motion.div
					initial="hidden"
					animate="visible"
					exit="hidden"
					variants={submenuVariants}
					className="absolute left-1/2 transform -translate-x-1/2 top-full flex justify-center mt-2 z-10"
				>
					<div className="bg-torch-red-500 flex p-3 rounded-full">
						<NavItem name={`${activeItem} Option 1`} link={`/${activeItem}/option1`} />
						<NavItem name={`${activeItem} Option 2`} link={`/${activeItem}/option2`} />
						<NavItem name={`${activeItem} Option 3`} link={`/${activeItem}/option3`} />
					</div>
				</motion.div>
			)}
		</div>
	);
}

export default RegularMenu;
