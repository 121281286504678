import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth, db } from "../../config/firebaseConfig";
import { doc, setDoc } from 'firebase/firestore';
import booking from "../../assets/img/logos/booking.png";

function Register() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [name, setName] = useState('');
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	const handleRegister = async () => {
		try {
			// Create the user
			const userCredential = await createUserWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;

			// Update the user's profile with the name
			await updateProfile(user, {
				displayName: name,
			});

			// Set role as 'user' by default in Firestore
			await setDoc(doc(db, 'users', user.uid), {
				name: name,
				email: email,
				role: 'user'
			});

			console.log('User registered:', user);

			// Redirect to the login page after successful registration
			navigate('/');
		} catch (error: any) {
			console.error('Error registering user:', error);
			setError(error.message);
		}
	};

	return (
		<div className="bg-torch-red-500 h-screen w-full flex justify-center items-center">
			<div className="bg-white w-1/2 mx-auto rounded-xl">
				<div className="w-1/3 mx-auto p-5">
					<img src={booking} alt="Booking logo" />
				</div>
				<div className="text-5xl font-black font-beastie m-5 text-torch-red-500 text-center">
					REGISTER
				</div>
				<div className="my-5 mx-5 flex justify-center items-center">
					<div className="flex flex-col justify-center w-1/2">
						<label className="text-2xl text-torch-red-500 font-black font-beastie-bold">Name</label>
						<input
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="bg-torch-red-500 text-white p-3 rounded-xl"
						/>
					</div>
				</div>
				<div className="my-5 mx-5 flex justify-center items-center">
					<div className="flex flex-col justify-center w-1/2">
						<label className="text-2xl text-torch-red-500 font-black font-beastie-bold">Email</label>
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="bg-torch-red-500 text-white p-3 rounded-xl"
						/>
					</div>
				</div>
				<div className="my-5 mx-5 flex justify-center items-center">
					<div className="flex flex-col justify-center w-1/2">
						<label className="text-2xl text-torch-red-500 font-black font-beastie-bold">Password</label>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="bg-torch-red-500 text-white p-3 rounded-xl"
						/>
					</div>
				</div>

				{error && <div className="text-red-500 text-center mt-3">{error}</div>}

				<div className="flex justify-center items-center m-5">
					<div
						onClick={handleRegister}
						className="bg-torch-red-500 px-5 py-2 text-white w-1/2 text-center font-beastie-bold text-2xl tracking-wider rounded-xl cursor-pointer hover:bg-white hover:text-torch-red-500 border-2 transition-all border-torch-red-500"
					>
						Register
					</div>
				</div>

				<div className="flex justify-center items-center m-5">
					<div>
						Already have an account? <a className="text-torch-red-500 font-beastie-bold" href="/login">Login!</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Register;
