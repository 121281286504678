import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Login from './pages/guest/Login';
import {BrowserRouter, createBrowserRouter, Outlet, Route, RouterProvider, Routes} from "react-router-dom";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import Register from "./pages/guest/Register";
import {AuthProvider, useAuth} from "./tools/AuthContext";
import ProtectedRoute from "./tools/ProtectedRoute";
import Home from "./pages/user/Home";
import Admin from "./pages/admin/Admin";
import ScrollToTop from "./tools/ScrollToTop";
import AdminRoute from "./tools/AdminRoute";
import Navbar from "./compnents/navbar";


const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<AuthProvider>
			<DndProvider backend={HTML5Backend}>
				<BrowserRouter>
					<ScrollToTop />
					<Navbar />
					<Routes>
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route element={<ProtectedRoute />}>

							<Route path="/" element={<Home />} />
						</Route>
						<Route element={<AdminRoute />}>
							<Route path="/admin" element={<Admin />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</DndProvider>
		</AuthProvider>

	</React.StrictMode>
);
