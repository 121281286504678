import React from "react";
import { useAuth } from "./AuthContext";
import {Outlet, Navigate} from "react-router-dom";

const ProtectedRoute: React.FC = () => {
	const { currentUser } = useAuth();

	return currentUser ? <Outlet /> : <Navigate to="/login" />
};

export default ProtectedRoute;
