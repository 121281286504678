

export type ContainerProps = {
	children: React.ReactNode;
}


const Container = ({children} : ContainerProps) => {
	return (
		<div className="w-[90%] mx-auto">
			{children}
		</div>
	)
}


export default Container;
