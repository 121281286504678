import React from "react";
import { useAuth } from "./AuthContext";
import {Outlet, Navigate} from "react-router-dom";

const ProtectedRoute: React.FC = () => {
	const { currentUser, role } = useAuth();

	return currentUser && role === "admin" ? <Outlet /> : <Navigate to="/" />
};

export default ProtectedRoute;
