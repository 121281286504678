// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyCTx0myLwZs0LxDLWYohql_B12dFwO3TUg",
	authDomain: "beastiebooking.firebaseapp.com",
	projectId: "beastiebooking",
	storageBucket: "beastiebooking.appspot.com",
	messagingSenderId: "598292604877",
	appId: "1:598292604877:web:d124076921f432ca43c684",
	measurementId: "G-2Z961CGKFD"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);


export {app, auth, db};
