import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebaseConfig';
import booking from "../../assets/img/logos/booking.png";

function Login() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState(null);

	const handleLogin = async () => {
		try {
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const user = userCredential.user;
			console.log('User logged in:', user);
			// Redirect or update the UI as needed after successful login
		} catch (error: any) {
			console.error('Error logging in:', error);
			setError(error.message);
		}
	};

	return (
		<div className="bg-torch-red-500 h-screen w-full flex justify-center items-center">
			<div className="bg-white w-1/2 mx-auto rounded-xl">
				<div className="w-1/3 mx-auto p-5">
					<img src={booking} />
				</div>
				<div className="text-5xl font-black font-beastie m-5 text-torch-red-500 text-center">
					LOGIN
				</div>
				<div className="my-5 mx-5 flex justify-center items-center">
					<div className="flex flex-col justify-center w-1/2">
						<label className="text-2xl text-torch-red-500 font-black font-beastie-bold">Email </label>
						<input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							className="bg-torch-red-500 text-white p-3 rounded-xl"
						/>
					</div>
				</div>
				<div className="my-5 mx-5 flex justify-center items-center">
					<div className="flex flex-col justify-center w-1/2">
						<label className="text-2xl text-torch-red-500 font-black font-beastie-bold">Password </label>
						<input
							type="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							className="bg-torch-red-500 text-white p-3 rounded-xl"
						/>
					</div>
				</div>

				{error && <div className="text-red-500 text-center mt-3">{error}</div>}

				<div className="flex justify-center items-center m-5">
					<div
						onClick={handleLogin}
						className="bg-torch-red-500 px-5 py-2 text-white w-1/2 text-center font-beastie-bold text-2xl tracking-wider rounded-xl cursor-pointer hover:bg-white hover:text-torch-red-500 border-2 transition-all border-torch-red-500">
						Login
					</div>
				</div>

				<div className="flex justify-center items-center m-5">
					<div>
						Don't have an account? <a className="text-torch-red-500 font-beastie-bold" href="/register">Register Now!</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;
