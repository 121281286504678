import { NavLink } from "react-router-dom";

export interface NavItemProps {
	name: string;
	link?: string;
	onClick?: () => void;
}

function NavItem({ name, link, onClick }: NavItemProps) {
	return (
		<li className="text-white font-black list-none text-nowrap uppercase">
			{link ? (
				<NavLink
					className="font-beastie-bold tracking-wide text-3xl"
					to={link}
				>
					{name}
				</NavLink>
			) : (
				<button
					onClick={onClick}
					className="font-beastie-bold tracking-wide text-3xl bg-transparent border-none cursor-pointer"
				>
					{name}
				</button>
			)}
		</li>
	);
}

export default NavItem;
