import {useWidth} from "../../hooks/useWidth";
import RegularMenu from "./RegularMenu";
import HamburgerMenu from "./HamburgerMenu";

const Navbar = () => {
	const regular = useWidth();
	return  regular ? <RegularMenu /> : <HamburgerMenu />
}


export default Navbar;
