


function HamburgerMenu() {
	return (
		<div className="">
			hamburger
		</div>
	)
}


export default HamburgerMenu;
